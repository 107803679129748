<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event && race">
      <Header :event="event"/>
      <v-card-title class="headline">
        Clone leaderboard <RaceSelector :event="event" :race="race" routeName="eventmanagerRaceCourse" />
      </v-card-title>

      <v-card-text>      
        Clone a leaderboard.
      </v-card-text>


      <v-card-text>
        <v-text-field v-model="request.name" label="Name of new leaderboard" />

        <p>Optional: set active window for cloned leaderboard:</p>
        <v-row>
          <v-col cols="12" md="4" class="py-0">
            <DateWithTimeZonePicker v-model="request.from" :timeZone="event.timezone" label="First date (included)" />
          </v-col>
          <v-col cols="12" md="4" class="py-0">
            <DateWithTimeZonePicker v-model="request.till" :timeZone="event.timezone" label="Last date (included)" />
          </v-col>
        </v-row>

        <p>Optional: clone as a team leaderboard</p>
        <v-checkbox v-model="request.team" true-value="TEAM" :false-value="null" label="Make this a team leaderboard" />

      </v-card-text>

      <v-card-text>
        <v-btn color="primary" large @click="submit">Clone</v-btn>
      </v-card-text>

    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import EventUtil from "@/util/eventUtil";

import siteData from '@/data/site.json'
import Header from './_Header.vue'
import RaceSelector from './_RaceSelector.vue'
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "RaceCompletionBadge",
  components: {
    Header,
    RaceSelector,
    DateWithTimeZonePicker,
  },
  props: {
  },
  data() {
    return {
      siteData: siteData,
      tenant: tenant,
      event: null,
      race: null,
      request: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.getEvent(to.params.id, to.params.raceId);
    next();
  },
  methods: {
    async getEvent(id, raceId) {
      this.event = (await eventManagerService.get(id)).data;
      this.race = this.event.races.find(r => r.id == raceId);
      this.request = { name: this.race.name };
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id, this.$route.params.raceId);
      }
    },

    async submit() {
      const response = (await eventManagerService.postRaceClone(this.event.id, this.race.id, this.request)).data;
      this.$helpers.toastResponse(this, response, 'Successfully cloned.');
      if (response.status == 'OK') {
        await this.eventUtil.refresh();
        this.$router.push({ name: 'eventmanagerView', params: { id: this.event.id }});
      }
    },


  },
  computed: {
    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },

    breadcrumbItems() {
      return !this.event || !this.race ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: this.race.name, exact: true, to: { name: 'eventmanagerRace', params: {id: this.event.id, raceId: this.race.id}} },
        { text: 'Clone', disabled: true },
      ];
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

